import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable()
export class FileService {
  constructor(
    private api: ApiService,
  ) {
  }

  putFileToStorage(url: string, file: File) {
    return this.api.putToUrl(url, file);
  }
}
